<template>
  <div class="user-container">
    <div class="header">
      <img src="@/assets/logo.png" class="logo" alt="logo" />
    </div>
    <div class="content">
      <router-view />
    </div>
    <div class="footer">
      <!-- <div class="links">
        <a href="#">帮助</a>
        <a href="#">隐私</a>
        <a href="#">条款</a>
      </div> -->
      <div class="copyright">
        Copyright &copy; {{moment().format('YYYY')}} 上海白鹿信息科技有限公司 
        <a href="http://www.beian.miit.gov.cn/" target="_blank">沪ICP备14040613号-4</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  methods: {
    moment
  },
}
</script>
